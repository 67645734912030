import useBreakpoints from '@/hooks/breakpoints'
import useMobileOperator from '@/hooks/mobile-operator'
import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'

// ------------------------------------------------------------------------------------>

const Predicate = {
  every: () => () => true,
  never: () => () => false,
  and: (p1, p2) => (data) => p1(data) && p2(data),
  or: (p1, p2) => (data) => p1(data) || p2(data),
  not: (p) => (data) => !p(data),

  // user context
  locale: (locales) => (data) => locales.includes(data.locale),
  mobile: () => (data) => data.os === 'Android' || data.os == 'iOS',
  breakpoint: (from) => (data) => {
    switch (from) {
      case 'sm':
        return data.breakpoints.isMobile || data.breakpoints.isTablet
      case 'md':
        return data.breakpoints.isDesktop
      case 'lg':
        return data.breakpoints.isDesktop && !data.breakpoints.isSmallDesktop
      case 'xl':
        return data.breakpoints.isDesktop && !data.breakpoints.isMediumDesktop
    }
    return true
  },

  // router
  path: (paths) => (data) => paths.includes(data.pathname),

  // order context
  origin:
    ({ country }) =>
    (data) =>
      data.order?.slices?.some(
        (slice) => slice.origin.country_code === country
      ),
  destination:
    ({ country }) =>
    (data) =>
      data.order?.slices?.some(
        (slice) => slice.destination.country_code === country
      ),
  limitToAmount: (max) => (data) => data.order?.total_amount <= max,
  travelInDays: (days) => (data) =>
    data.order?.isDepartureDateInLessThanDays(days),
}

/**
 * hook to filter constants
 * @param {Object} context a context, if needed to be given on filter execution
 * @returns
 */
export const useFilteredConstants = (context) => {
  const { locale, pathname } = useRouter()
  const breakpoints = useBreakpoints()
  const os = useMobileOperator()

  const data = useMemo(() => {
    if (context) {
      return { pathname, locale, breakpoints, os, ...context }
    }
    return { pathname, locale, breakpoints, os }
  }, [locale, breakpoints, os])

  const filter = useCallback(
    (toFilter) =>
      typeof toFilter === 'object'
        ? Object.entries(toFilter)
            .filter(([, v]) => v(data))
            .map(([k]) => k)
        : typeof toFilter === 'function'
        ? toFilter(data)
        : null,
    [data]
  )

  return {
    // the computed predicate data
    predicateData: data,
    // apply a predicate, with the data, to filter the given constants
    // return an array of string
    // ex : useConstants().filter(HOME_MESSAGES)
    apply: filter,
  }
}

/**
 * Creates an experiment conf with the variants, the weights and a method to get the value
 *
 * The experiment variant configuration can be:
 *  - a number of variants that will create a list from 'a' to ... 'z' evenly distributed
 *  - an array with variant names, that will be evenly distributed
 *  - an object with variant names as keys, and variant weight as value.
 *    If values are null, the weight are computed to be evenly distributed
 *
 * @param {string} name
 * @param {number|Array|Object} variants the version configuration
 * @param {string} defaultVariant
 */
export function createExperiment({
  variants,
  activated = true,
  defaultVariant = 'a',
  predicate = () => true, // deactivate programaticaly the experiment
}) {
  if (!activated) {
    return {
      variants: [defaultVariant],
      weights: [1],
      get: () => ({
        activated: false,
        version: defaultVariant,
      }),
    }
  }

  // create the object oriented conf
  let values
  let weights

  if (typeof variants === 'number' && variants > 0 && variants <= 26) {
    // number => generate variants 'a', 'b' ....
    values = Array.from({ length: variants }, (_, i) =>
      String.fromCharCode(97 + i)
    )
    weights = values.map(() => null)
  } else if (Array.isArray(variants) && variants.length > 0) {
    // array => generate an object with variants as keys
    values = variants
    weights = values.map(() => null)
  } else if (typeof variants === 'object' && Object.keys(variants).length > 0) {
    // keep the object
    values = Object.keys(variants)
    weights = Object.values(variants)
  }

  if (!values?.length) {
    return {
      variants: [defaultVariant],
      weights: [1],
      get: () => ({
        activated: true,
        version: defaultVariant,
      }),
    }
  }

  const validWeight = (w) => w != null && w >= 0
  const weigthed = weights.filter(validWeight)
  const total = weigthed.reduce((t, w) => w + t, 0)

  // complete the missing weight
  if (weigthed.length < weights.length) {
    const remaining = 1 - total
    if (remaining < 0) {
      throw new Error("The sum of the total weight can't be greater than 1")
    }

    // share the remaining with the number of missing weighted variants
    const remainingWeight = remaining / (weights.length - weigthed.length)
    weights = weights.map((w) => (validWeight(w) ? w : remainingWeight))
  } else if (total !== 1) {
    throw new Error("The sum of the total weight can't be different from 1")
  }

  // finally, update the weights with the previous value
  for (let i = 1; i < weights.length; i++) weights[i] += weights[i - 1]

  return {
    variants: values,
    weights: weights,
    get: (context) => {
      if (predicate && !predicate(context)) {
        return {
          activated: false,
          version: defaultVariant,
        }
      }

      const random = Math.random()
      for (let i = 0; i < weights.length; i++) {
        if (weights[i] >= random)
          return {
            activated: true,
            version: values[i],
          }
      }
      return { activated: true, version: values[values.length - 1] }
    },
  }
}

// ------------------------------------------------------------------------------------>

// is the website in maintenance
export const MAINTENANCE = false

export const STORE_IOS_URL =
  'https://apps.apple.com/app/ulysse-book-your-flights/id1607324315'
export const STORE_ANDROID_URL =
  'https://play.google.com/store/apps/details?id=com.ulysse.mobile.app'

export const KOLET_STORE_IOS_URL =
  'https://apps.apple.com/cm/app/kolet-travel-e-sim/id6471825242'
export const KOLET_STORE_ANDROID_URL =
  'https://play.google.com/store/apps/details?id=com.kolet.gamezone'

// Determines the current event
// possible value xmas, valentine
// null if no events
export const CURRENT_EVENT = null
export const isXMAS = () => CURRENT_EVENT == 'xmas'

export const WHATSAPP_URL = null //'https://wa.me/33616599061'
export const DARK_PAGES = [
  '/',
  '/trips/[id]',
  '/trips/[id]/post-booking-services',
  '/crypto',
  '/flight-club',
  '/flight-club/success',
  '/conciergerie',
  '/cars/search',
  '/cars/details/[id]',
  '/checkout/[id]/success',
  isXMAS() ? '/gift' : '/',
]

// Determines on which page to display the app banner.
// let an empty array for nowhere
export const DISPLAY_APP_BANNER = []
export const DISPLAY_APP_BANNER_PARTNER_EXCLUSION = ['petitfute']

// Determines on which page to display the banner
export const DISPLAY_BANNER = false

// Messages displayed on the home (the order is preserved)
// possible values {'giftcard', 'airport', 'downloadapp' : Predicate.breakpoint('md'), 'strike' : Predicate.locale('fr')}
// set empty to disable all
// if you chosse strike, be sure to update the according message
export const HOME_MESSAGES = {
  // downloadapp: Predicate.and(Predicate.breakpoint('md'), Predicate.mobile()),
  carrentals: Predicate.and(
    Predicate.breakpoint('md'),
    Predicate.locale(['fr'])
  ),
}

export const DONNATION_AMOUNTS = [] //[5, 10, 20, 50]

// Messages displayed on the flight result page
// possible values ['giftcard', 'feedback', 'strike']
// set empty to disable all
// if you chosse strike, be sure to update the according message
export const PREBOOKING_MESSAGES = []

// travel guide share a place email address
export const SHARE_A_PLACE_LINK = 'mailto:share.a.place@ulysse.com'

// Types of gift cards that are currently enabled
// Possibles values are 'electronic', 'printable' and 'cover'
export const ENABLED_GIFT_CARD_TYPES = ['electronic', 'printable'].concat(
  isXMAS() ? ['cover'] : []
)

// possible values {'checkout', 'coinbase', 'alma', 'paypal'}
export const AUTHORIZED_PAYMENT_METHODS = {
  checkout: Predicate.every(),
  googlepay:
    process.env.NEXT_PUBLIC_ENABLE_GOOGLEPAY === 'true'
      ? Predicate.every()
      : Predicate.never(),
  coinbase: Predicate.every(),
  alma: Predicate.limitToAmount(2500),
  // paypal: Predicate.not(
  //   Predicate.or(
  //     // for every travel in GR in the next 5 days
  //     Predicate.and(
  //       Predicate.destination({ country: 'GR' }),
  //       Predicate.travelInDays(5)
  //     ),

  //     // for every travel from Cuba or to Cuba
  //     Predicate.or(
  //       Predicate.origin({ country: 'CU' }),
  //       Predicate.destination({ country: 'CU' })
  //     )
  //   )
  // ),
}

// australia partnerships slugs
export const AUSTRALIA_PARTNERSHIPS = [
  'australie',
  'pvtistes-australia',
  'whvaustralie',
  'kowalapvtaus',
  'studiesup-ausnz',
  'pvtistes-nz',
  'allaroundtravel-ausnz',
  'shine-away',
]

/**
 * Experiments.
 * use EXPERIMENTS[name].get() to get the valid value
 */
export const EXPERIMENTS = {
  alternative_offers: createExperiment({
    variants: { a: 1, b: -1, c: -1 },
    activated: false,
  }),
  leroutard_result_list: createExperiment({
    variants: 2,
    activated: false,
  }),
  display_checkedbag: createExperiment({
    variants: { a: 0.5, b: 0.25, c: 0.25 },
    activated: false,
    defaultVariant: 'c', // we choose the 'c' for now
  }),
  spot_label: createExperiment({
    variants: 2,
  }),
  new_mobile_form: createExperiment({
    variants: { a: -1, b: 1 },
    predicate: (context) => context?.locale === 'fr',
    defaultVariant: 'b',
    activated: false,
  }),
  mobile_best_prices: createExperiment({
    variants: 2,
    predicate: (context) => context?.locale === 'fr',
    activated: false,
    defaultVariant: 'a',
  }),
}

// if no promos, GIFTCARD_PROMOS=null
export const GIFTCARD_PROMOS = {
  // the value in the params p=4t8e9k-2024
  '4t8e9k-2024': {
    maxAmount: 300, // the max amount if any
    tags: ['promo:4t8e9k-2024', 'restriction:not-cumulative'],
    // the promocode to apply, limited to the max given amount (included)
    codes: {
      'SAVING-ULYSSE-GIFTCARD-10': 100,
      'SAVING-ULYSSE-GIFTCARD-5': 300,
    },
  },
}

export const DISPLAY_LUGGAGE_CONFIRMATION_POPUP = true
